<template>
  <Default ref="regulator"></Default>
</template>

<script>
import Default from '@/components/template/register/userDetail/Default';

export default {
  components: { Default }
};
</script>
