<template>
  <el-form :model="form" :rules="rules" ref="ruleForm">
    <el-row :gutter="60">
      <el-col :xs="24" :sm="12" :md="8">
        <SelectForm
          v-model="form.title"
          :disabled="disableField"
          :label="$t('register.personalDetails.page1.userTitle')"
          name="title"
        >
          <el-option
            v-for="(item, index) in titleList"
            :key="index"
            :label="item.label"
            :value="item.value"
            :data-testid="'title0' + (index + 1)"
          ></el-option>
        </SelectForm>
      </el-col>
      <el-col :xs="24" :sm="12" :md="8">
        <InputForm
          :label="$t('register.personalDetails.page1.email')"
          type="email"
          name="email"
          v-model="form.email"
          readonly
        ></InputForm>
      </el-col>
    </el-row>
    <el-row :gutter="60">
      <el-col :xs="24" :sm="12" :md="8">
        <InputForm
          :label="$t('register.personalDetails.page1.firstName')"
          name="firstName"
          v-model="form.firstName"
          :disabled="disableField"
          maxlength="128"
        ></InputForm>
      </el-col>
      <el-col :xs="24" :sm="12" :md="8">
        <InputForm
          :label="$t('register.personalDetails.page1.middleName')"
          name="middleName"
          v-model="form.middleName"
          :disabled="disableField"
          maxlength="128"
        ></InputForm>
      </el-col>
      <el-col :xs="24" :sm="12" :md="8">
        <InputForm
          :label="$t('register.personalDetails.page1.lastName')"
          name="lastName"
          v-model="form.lastName"
          :disabled="disableField"
          maxlength="128"
        ></InputForm>
      </el-col>
    </el-row>
    <el-row :gutter="60">
      <el-col :xs="24" :sm="12" :md="8">
        <SelectForm
          v-model="form.nationalityId"
          :disabled="disableField"
          :label="$t('register.personalDetails.page1.nationality')"
          name="nationalityId"
        >
          <el-option
            v-for="(item, index) in nationalities"
            :key="index"
            :label="item.nationality"
            :value="item.id"
            :data-testid="item.id < 10 ? 'nationality0' + item.id : 'nationality' + item.id"
          ></el-option>
        </SelectForm>
      </el-col>
      <el-col :xs="24" :sm="12" :md="8">
        <SelectForm
          v-model="form.placeOfBirth"
          :disabled="disableField"
          :label="$t('register.personalDetails.page1.placeOfBirth')"
          name="placeOfBirth"
        >
          <el-option
            v-for="(item, index) in birthPlace"
            :key="index"
            :label="item.countryName"
            :value="item.id"
            :data-testid="item.id"
          ></el-option>
        </SelectForm>
      </el-col>
      <el-col :xs="24" :sm="12" :md="8">
        <el-form-item class="date_birth" prop="dob">
          <div class="labelTitle">
            <span class="required_icon">*</span>
            <label for="">{{ $t('register.personalDetails.page1.dob') }}</label>
          </div>
          <div class="row">
            <SelectForm
              v-model="dateOfBirth.date"
              name="dob"
              :placeholder="$t('register.personalDetails.page1.date')"
              class="showPlaceholder"
              :disabled="disableField"
            >
              <el-option
                v-for="(item, index) in dates"
                :key="index"
                :label="item"
                :value="item"
                :data-testid="'date' + item"
              ></el-option>
            </SelectForm>

            <SelectForm
              v-model="dateOfBirth.month"
              :placeholder="$t('register.personalDetails.page1.month')"
              name="month"
              class="showPlaceholder"
              :disabled="disableField"
            >
              <el-option
                v-for="month in months"
                :key="month"
                :label="month"
                :value="month"
                :data-testid="'month' + month"
              ></el-option>
            </SelectForm>

            <SelectForm
              v-model="dateOfBirth.year"
              :placeholder="$t('register.personalDetails.page1.year')"
              name="year"
              class="showPlaceholder"
              :disabled="disableField"
            >
              <el-option v-for="item in years" :key="item" :label="item" :value="item" :data-testid="item"></el-option>
            </SelectForm>
          </div>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row :gutter="60" class="lessMarginBottom">
      <el-col :xs="24" :sm="12" :md="8">
        <el-form-item class="phone" prop="phoneCode">
          <div class="labelTitle">
            <span class="required_icon">*</span>
            <label for="">{{ $t('register.personalDetails.page1.phoneNum') }}</label>
          </div>
          <div class="row">
            <SelectForm v-model="form.phoneCode" :disabled="disableField" name="phoneCode">
              <el-option
                v-for="(item, index) in phoneCodes"
                :key="index"
                :label="item"
                :value="item"
                :data-testid="'phoneCode' + item"
              ></el-option>
            </SelectForm>

            <InputForm name="mobile" v-model="form.mobile" :disabled="disableField"></InputForm>
          </div>
        </el-form-item>
      </el-col>
      <el-col :xs="24" :sm="12" :md="8">
        <SelectForm
          v-model="form.idType"
          :disabled="disableField"
          :label="$t('register.personalDetails.page1.idType')"
          name="idType"
        >
          <el-option
            v-for="(item, index) in identificationTypes"
            :key="index"
            :label="item.label"
            :value="item.value"
            :data-testid="'identificationTypes0' + item.value"
          ></el-option>
        </SelectForm>
      </el-col>
      <el-col :xs="24" :sm="12" :md="8">
        <InputForm :label="idTypeLabel" :disabled="disableField" name="idNumber" v-model="form.idNumber"></InputForm>
      </el-col>
    </el-row>
    <el-row :gutter="60">
      <el-col :xs="24" :sm="12" :md="8">
        <el-form-item prop="name" class="checkbox">
          <el-checkbox
            :label="$t('register.personalDetails.page1.referral')"
            name="type"
            v-model="referredCheck"
            :disabled="disableField"
            data-testid="referredCheck"
          ></el-checkbox>
          <InputForm
            type="text"
            :disabled="disableField"
            v-if="referredCheck"
            name="referredBy"
            v-model="form.referredBy"
          ></InputForm>
        </el-form-item>
      </el-col>
    </el-row>
  </el-form>
</template>

<script>
import userDetail from '@/mixins/register/userDetail';

export default {
  mixins: [userDetail],
  data() {
    return {
      titleList: [
        { value: 'Mr', label: this.$t('register.personalDetails.page1.titleDropdown.mr') },
        { value: 'Mrs', label: this.$t('register.personalDetails.page1.titleDropdown.mrs') },
        { value: 'Ms', label: this.$t('register.personalDetails.page1.titleDropdown.ms') },
        { value: 'Miss', label: this.$t('register.personalDetails.page1.titleDropdown.miss') },
        { value: 'Dr', label: this.$t('register.personalDetails.page1.titleDropdown.dr') },
        { value: 'Prof', label: this.$t('register.personalDetails.page1.titleDropdown.prof') }
      ],
      identificationTypes: [
        { value: 1, label: this.$t('register.personalDetails.page1.idTypeDropdown.idCard') },
        { value: 2, label: this.$t('register.personalDetails.page1.idTypeDropdown.passport') },
        { value: 3, label: this.$t('register.personalDetails.page1.idTypeDropdown.driverLicense') }
      ]
    };
  },
  computed: {
    birthPlace() {
      return this.$store.state.register.birthPlace;
    },
    idTypeLabel() {
      switch (this.form.idType) {
        case 2:
          return this.$t('register.personalDetails.page1.passportNum');
        case 3:
          return this.$t('register.personalDetails.page1.driverLicenseNum');
        default:
          return this.$t('register.personalDetails.page1.idNum');
      }
    }
  },
  watch: {
    idTypeLabel(label) {
      this.rules.idNumber[0].message = this.$t('common.formValidation.dynamicReq', {
        dynamic: this.idTypeLabel
      });
      this.$refs.ruleForm.validateField('idNumber');
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/components/register/personalDetails.scss';
</style>
